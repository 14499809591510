import React, { useState, useEffect, useRef } from 'react'
import { render } from "react-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async'

import Header from '../../components/Header'
import './style.css'
import Ticket from '../../assets/images/ticket.png'
import Procedure from '../../assets/images/procedure.png'
import System from '../../assets/images/system.png'
import Scrumble from '../../assets/images/scrumble.jpg'
const style = {backgroundImage:`url(${Scrumble})`}

const HomePage = (props) => {
    return (
        <HelmetProvider>
        <>
            <Header />
            <Helmet>
              <title>Qmax予約システム</title>
              <meta name="description" content="Qmax予約システムは、店頭でお客様のご来店予測時間が書かれた整理券を発行することが可能です。これによりお客様を店頭にお待たせすることなく、予定時間にご案内することが可能です。" />
              <script src="contact.js" type="text/javascript" />
            </Helmet>
            
            <div className="container" style={{paddingTop: 70}}>
                <div className="landing-page">
                    <div className="section" style={style}>
	                    <div className="content">
		                        <div className="message">
				                    <h1 className="head-tag">お客様の満足度向上を目指して</h1>
				                    <div className="head-desc">もう、お客様を店頭でお待たせしません</div>
				                    <div className="head-desc">順番待ちチケットがお客様をご案内</div>
				                    <div className="head-desc">お客様は、携帯から最新の案内予定時間を確認可能</div>
			                    </div>
			                    <img src={Ticket} className="img" alt="チケット" />
	                    </div>
                    </div>
                    <div className="title">こんなお悩みがある店舗オーナーに最適なシステムです</div>
                    <div className="line">・コロナ禍において、来店者の行列が出来て心配</div>
                    <div className="line">・お客様を、ただひたすらお待たせするのではなく、新しいことにチャレンジしたい</div>
                    <div className="line">・来店者数がある程度先に分かると楽なんだが</div>
                    <div className="title">Qmax予約システムで何ができますか？</div>
                    <div className="line">お待ちのお客様へ整理券を発行することが出来るクラウドサービスです。</div>
                    <div className="line">整理券には、番号と案内予定時間が記載されるのでお客様は店頭に並ぶ必要がなくなります。</div>

                    <img src={Procedure} className="img2" />
                    <div className="title">Qmax予約システムは、何が必要ですか？</div>
                    <div className="line">システムのご利用には、次のものが必要です。</div>
                    <div className="line">①整理券発券機　②顧客対応管理端末　③インターネット環境</div>
                    <img src={System} className="img2" />
                </div>
            </div>
        </>
        </HelmetProvider>
    );
};

export default HomePage;